import './NavbarWhite.css';

import {
  Box,
  Button,
  Drawer,
  Grid,
  Hidden,
  IconButton,
} from '@material-ui/core';
import React, { useState } from 'react';

import { AnchorLink } from 'gatsby-plugin-anchor-links';
import AppBar from '@material-ui/core/AppBar';
import { Link } from 'gatsby';
import MainDrawer from '../MainDrawer';
import MenuIcon from '@material-ui/icons/Menu';
import ShapyaLogoRed from '../../images/Logo_ShapYa_Red.png';
import Toolbar from '@material-ui/core/Toolbar';
import TranslateIcon from '@material-ui/icons/Translate';
import { makeStyles } from '@material-ui/core/styles';
import scrollTo from 'gatsby-plugin-smoothscroll';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#FAF2F2',
    fontFamily: 'Mulish',
  },
  appbarstyles: {
    maxWidth: '1440px',
  },
  shapyalogo: {
    height: '52px',
    '@media(max-width: 599px)': {
      height: '41px',
    },
  },
  shapyaNavbarLogo: {
    width: '175px',
    height: '52px',
    '@media(max-width: 599px)': {
      width: '137px',
      height: '41px',
    },
  },
  navbarlink: {
    textDecoration: 'none',
    color: '#000000',
    fontSize: '18px',
    fontWeight: '700',
    margin: theme.spacing(3),
    '&:hover': {
      color: '#c43c42',
    },
  },
  loginlink: {
    textDecoration: 'none',
    color: '#000000',
    fontSize: '16px',
    fontWeight: '700',
    margin: theme.spacing(1),
    border: 'solid 1px #4F4F4F',
    borderRadius: 5,
    padding: '11px 18px',
    '&:hover': {
      color: '#c43c42',
      border: 'solid 1px #c43c42',
    },
  },
  signUpButton: {
    color: '#fff',
    fontSize: '16px',
    fontWeight: '700',
    margin: theme.spacing(1),
    backgroundColor: '#c43c42',
    border: 'solid 1px #C43C42',
    borderRadius: 5,
    textAlign: 'center',
    textDecoration: 'none',
    padding: '11px 18px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  translationbutton: {
    border: 'solid 2px #4F4F4F',
    borderRadius: 5,
    color: '#000000',
    fontSize: '16px',
    fontFamily: 'Mulish',
    fontWeight: '700',
    marginLeft: theme.spacing(1),
  },
  divdrawer: { width: '250px' },
  menuicon: { color: '#1F1F1F' },
}));

const NavbarWhite = () => {
  const classes = useStyles();

  const { t, i18n } = useTranslation();

  let languageDetector = i18n.languages[0].substr(0, 2);
  if (languageDetector === 'es') {
    languageDetector = 'en';
  } else {
    languageDetector = 'es';
  }
  const [language, setLanguage] = useState(languageDetector);
  const onChangeLanguage = () => {
    i18n.changeLanguage(language);
    if (language === 'es') {
      setLanguage('en');
    } else {
      setLanguage('es');
    }
  };

  const [open, setOpen] = useState(false);

  const handleDrawer = () => {
    setOpen(true);
  };

  return (
    <div className="shapyaMaxWidth">
      <div className={classes.root}>
        <AppBar
          position="static"
          color="transparent"
          elevation={0}
          className={classes.appbarstyles}
        >
          <Toolbar>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid>
                <Box className={classes.shapyalogo}>
                  <Link to="/">
                    <img
                      src={ShapyaLogoRed}
                      alt="ShapYa"
                      className={classes.shapyaNavbarLogo}
                    />
                  </Link>
                </Box>
              </Grid>

              <Grid>
                <Hidden mdDown>
                  <Link
                    to="/"
                    activeStyle={{ color: '#c43c42' }}
                    partiallyActive={false}
                    className={classes.navbarlink}
                  >
                    {t('navbar.home')}
                  </Link>

                  <Link
                    to="/our-stores/"
                    activeStyle={{ color: '#c43c42' }}
                    partiallyActive={true}
                    className={classes.navbarlink}
                  >
                    {t('navbar.shop')}
                  </Link>

                  <Link
                    to="https://blog.shapya.com/"
                    activeStyle={{ color: '#c43c42' }}
                    partiallyActive={false}
                    className={classes.navbarlink}
                  >
                    {t('navbar.blog')}
                  </Link>

                  <Link
                    to="/about-us/"
                    activeStyle={{ color: '#c43c42' }}
                    partiallyActive={true}
                    className={classes.navbarlink}
                  >
                    {t('navbar.aboutus')}
                  </Link>

                  <Link
                    to="/#learnMoreSuite"
                    activeStyle={{ color: '#c43c42' }}
                    partiallyActive={true}
                    className={classes.navbarlink}
                  >
                    {t('navbar.contactUs')}
                  </Link>
                </Hidden>
              </Grid>

              <Grid>
                <Hidden mdDown>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <a
                      href="https://enterprise.shapya.com/"
                      className={classes.loginlink}
                    >
                      {t('navbar.login')}
                    </a>

                    <a
                      href="https://enterprise.shapya.com/signup/"
                      className={classes.signUpButton}
                    >
                      {t('navbar.signUp')}
                    </a>

                    <Button
                      color="inherit"
                      variant="outlined"
                      size="small"
                      startIcon={<TranslateIcon />}
                      onClick={onChangeLanguage}
                      className={classes.translationbutton}
                    >
                      {t('navbar.button.language')}
                    </Button>
                  </Grid>
                </Hidden>

                <Hidden lgUp>
                  <IconButton onClick={handleDrawer}>
                    <MenuIcon fontSize="large" className={classes.menuicon} />
                  </IconButton>
                  <Drawer
                    anchor="left"
                    open={open}
                    onClose={() => setOpen(false)}
                  >
                    <div className={classes.divdrawer}>
                      <MainDrawer />
                    </div>
                  </Drawer>
                </Hidden>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </div>
    </div>
  );
};

export default NavbarWhite;
