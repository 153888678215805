import './styles/Footer.css';

import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Hidden } from '@material-ui/core';
import ImgLogoShapyaWhiteFluidH from './imgComponents/imgLogoShapyaWhiteFluidH';
import { Link } from 'gatsby';
import React from 'react';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <div className="shapyaMaxWidth">
      <div className="footerMainDiv">
        <div className="footerTopContentDiv">
          <div className="footerShapYaLogo">
            <Link to="/">
              <ImgLogoShapyaWhiteFluidH direction="row" />
            </Link>
          </div>

          <Hidden lgUp>
            <div className="footerSocialMediaLinks">
              <a
                href="https://www.facebook.com/ShapYaSuite"
                target="_blank"
                className="socialmediabtn"
              >
                <FontAwesomeIcon icon={faFacebook} size="2x" />
              </a>
              <a
                href="https://www.instagram.com/shapya_us/"
                target="_blank"
                className="socialmediabtn"
              >
                <FontAwesomeIcon icon={faInstagram} size="2x" />
              </a>
              <a
                href="https://www.youtube.com/@shapya"
                target="_blank"
                className="socialmediabtn"
              >
                <FontAwesomeIcon icon={faYoutube} size="2x" />
              </a>
              <a
                href="https://www.linkedin.com/company/shapya/"
                target="_blank"
                className="socialmediabtn"
              >
                <FontAwesomeIcon icon={faLinkedin} size="2x" />
              </a>
            </div>
          </Hidden>

          <div className="footerPagesLinks">
            <Link to="/" className="shapyaLinkFooter2">
              {t('terms.home')}
            </Link>

            <span>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</span>

            <Link to="/terms/" className="shapyaLinkFooter2">
              {t('terms.termsconditions')}
            </Link>

            <span>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</span>

            <Link to="/privacypolicy/" className="shapyaLinkFooter2">
              {t('terms.privacypolicy')}
            </Link>

            <span>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</span>

            <Link to="/author-attributions/" className="shapyaLinkFooter2">
              {t('terms.authorattributions')}
            </Link>
          </div>

          <Hidden mdDown>
            <div className="footerSocialMediaLinks">
              <a
                href="https://www.facebook.com/ShapYaSuite"
                target="_blank"
                className="socialmediabtn"
              >
                <FontAwesomeIcon icon={faFacebook} size="2x" />
              </a>
              <a
                href="https://www.instagram.com/shapya_us/"
                target="_blank"
                className="socialmediabtn"
              >
                <FontAwesomeIcon icon={faInstagram} size="2x" />
              </a>
              <a
                href="https://www.youtube.com/@shapya"
                target="_blank"
                className="socialmediabtn"
              >
                <FontAwesomeIcon icon={faYoutube} size="2x" />
              </a>
              <a
                href="https://www.linkedin.com/company/shapya/"
                target="_blank"
                className="socialmediabtn"
              >
                <FontAwesomeIcon icon={faLinkedin} size="2x" />
              </a>
            </div>
          </Hidden>
        </div>

        <div className="footerMiddleContentDiv">
          <hr />
        </div>

        <div className="footerBottomContentDiv">
          <span className="shapyaAllRights">
            ShapYa inc.&nbsp;&nbsp;{t('terms.allrights')}
            .&nbsp;&nbsp;&nbsp;&copy; {new Date().getFullYear()}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
