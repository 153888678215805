import enTranslation from './en.json';
import esTranslation from './es.json';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: enTranslation,
  },
  es: {
    translation: esTranslation,
  },
};

const shapyaLanguageDetector = () => {
  if (typeof window !== 'undefined') {
    if (window.location.origin === 'https://shapya.co') {
      return 'es';
    }
    return 'en';
  }
  return 'en';
};

i18n.use(initReactI18next).init({
  resources,
  lng: shapyaLanguageDetector(),
  fallbackLng: shapyaLanguageDetector(),

  keySeparator: false,

  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
