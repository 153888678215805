import '../components/navbar/NavbarWhite.css';

import { Box, Button, Divider } from '@material-ui/core';
import React, { useState } from 'react';

import Collapse from '@material-ui/core/Collapse';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import DescriptionIcon from '@material-ui/icons/Description';
import DirectionsBikeIcon from '@material-ui/icons/DirectionsBike';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import HomeIcon from '@material-ui/icons/Home';
import ImgAppStoreFluid150 from './imgComponents/imgAppStoreFluid150';
import ImgGooglePlayFluid150 from './imgComponents/imgGooglePlayFluid150';
import ImgLogoShapyaRedFluidLimit from './imgComponents/imgLogoShapyaRedFluidLimit';
import { Link } from 'gatsby';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PermDeviceInformationIcon from '@material-ui/icons/PermDeviceInformation';
import StoreIcon from '@material-ui/icons/Store';
import StorefrontIcon from '@material-ui/icons/Storefront';
import TranslateIcon from '@material-ui/icons/Translate';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import WorkIcon from '@material-ui/icons/Work';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  translatebutton: {
    border: 'solid 2px #000',
    borderRadius: 3,
    color: '#000',
    fontFamily: 'Montserrat',
    fontWeight: '700',
  },
}));

export default function NestedList() {
  const { t, i18n } = useTranslation();

  let languageDetector = i18n.languages[0].substr(0, 2);
  if (languageDetector === 'es') {
    languageDetector = 'en';
  } else {
    languageDetector = 'es';
  }
  const [language, setLanguage] = useState(languageDetector);
  const onChangeLanguage = () => {
    i18n.changeLanguage(language);
    if (language === 'es') {
      setLanguage('en');
    } else {
      setLanguage('es');
    }
  };

  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <Box mb={2} mt={2} align="center">
          <ImgLogoShapyaRedFluidLimit />
        </Box>
      }
      className={classes.root}
    >
      <Divider />

      <ListItem
        button
        component={Link}
        to="/"
        activeStyle={{ backgroundColor: '#f2f2f2' }}
      >
        <ListItemIcon>
          <HomeIcon />
        </ListItemIcon>
        <ListItemText primary={t('navbar.home')} />
      </ListItem>

      <Divider />

      <ListItem
        button
        component={Link}
        to="/our-stores/"
        activeStyle={{ backgroundColor: '#f2f2f2' }}
      >
        <ListItemIcon>
          <StoreIcon />
        </ListItemIcon>
        <ListItemText primary={t('navbar.shop')} />
      </ListItem>

      <Divider />

      <ListItem
        button
        component={Link}
        to="https://blog.shapya.com/"
        activeStyle={{ backgroundColor: '#f2f2f2' }}
      >
        <ListItemIcon>
          <DescriptionIcon />
        </ListItemIcon>
        <ListItemText primary={t('navbar.blog')} />
      </ListItem>

      <Divider />

      <ListItem
        button
        component={Link}
        to="/about-us/"
        activeStyle={{ backgroundColor: '#f2f2f2' }}
      >
        <ListItemIcon>
          <PermDeviceInformationIcon />
        </ListItemIcon>
        <ListItemText primary={t('navbar.aboutus')} />
      </ListItem>

      <Divider />

      <ListItem
        button
        component={Link}
        to="/#learnMoreSuite"
        activeStyle={{ backgroundColor: '#f2f2f2' }}
        onClick={() => setOpen(false)}
      >
        <ListItemIcon>
          <ContactMailIcon />
        </ListItemIcon>
        <ListItemText primary={t('navbar.contactUs')} />
      </ListItem>

      <Divider />

      <ListItem
        button
        component="a"
        href="https://enterprise.shapya.com/signUp/"
      >
        <ListItemIcon>
          <WorkIcon />
        </ListItemIcon>
        <ListItemText primary={t('navbar.joinShapya')} />
      </ListItem>

      <Divider />

      <ListItem button component="a" href="https://enterprise.shapya.com/">
        <ListItemIcon>
          <VpnKeyIcon />
        </ListItemIcon>
        <ListItemText primary={t('navbar.login')} />
      </ListItem>
      <Divider />
      <Box mb={2} mt={2} align="center">
        <a href="https://apps.apple.com/us/app/id1522176109" target="_blank">
          <ImgAppStoreFluid150 />
        </a>
      </Box>
      <Box mb={2} mt={2} align="center">
        <a
          href="https://play.google.com/store/apps/details?id=com.shapya.app"
          target="_blank"
        >
          <ImgGooglePlayFluid150 />
        </a>
      </Box>
      <Box mb={4} mt={2} align="center">
        <Button
          color="inherit"
          variant="outlined"
          size="small"
          startIcon={<TranslateIcon />}
          onClick={onChangeLanguage}
          className={classes.translatebutton}
        >
          {t('navbar.button.language')}
        </Button>
      </Box>
    </List>
  );
}
