import { graphql, useStaticQuery } from 'gatsby';

import Img from 'gatsby-image';
import React from 'react';

const ImgLogoShapyaRedFluidLimit = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "Logo_ShapYa_Red.png" }) {
        childImageSharp {
          fluid(maxWidth: 137, maxHeight: 41, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);
  return <Img fluid={data.placeholderImage.childImageSharp.fluid} />;
};

export default ImgLogoShapyaRedFluidLimit;
